import React, { Component } from 'react';

import christianityExplored from '../../../assets/Christianity Explored Card.png';
import growthGroups from '../../../assets/Growth Groups.png';
import leafYouth from '../../../assets/leaf-youth-thumb.png';
import littleBuddies from '../../../assets/Little Buddies.png';
import sundaySchool from '../../../assets/Card - Sunday School.png';
import sundays from '../../../assets/Sundays.png';



class WhatWeDo extends Component {
  render() {
    return (
      <section>
        <div id="top-content-region" className="top-content padding-top-15 padding-bottom-15 block-15 bg-color-grayLight1">
          <div className="container">
            <div className="row">
              <div id="top-content-left-region" className="top-content-left col-xs-12 col-md-6 text-center-sm">
                <div id="page-title-block" className="page-title block">
                  <h1>What We Do</h1>
                </div>
              </div>

              <div id="top-content-right-region" className="top-content-right col-xs-12 col-md-6 text-right text-center-sm">
                <div id="page-breadcrumbs-block" className="page-breadcrumbs block">
                  <div className="breadcrumbs">
                    <a href="/">Home</a>
                    <span className="delimiter">›</span>
                    <span title="" className="nolink">About</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="content-region">
          <div className="container">
            <div className="row">
              <div id="main-content-region" className="main-content col-xs-12">
                <div className="region region-content">
                  <div className="content">
                    <div className="node node-page clearfix">
                      <div className="content">

                        <div className="row text-center">
                          <div className="col-md-3 col-sm-6">
                            <a href="/ChristianityExplored"><img className="img img-responsive" width="217" height="140" src={christianityExplored} alt="Christianity Explored" title="Christianity Explored" /></a>
                            <h4><a href="/ChristianityExplored">Christianity Explored</a></h4>
                          </div>


                          <div className="col-md-3 col-sm-6">
                            <a href="/WhatWeDo/Sundays"><img className="img img-responsive" width="217" height="140" src={sundays} alt="Sundays" title="Sundays" /></a>
                            <h4><a href="/WhatWeDo/Sundays">Sundays</a></h4>
                          </div>

                          <div className="col-md-3 col-sm-6">
                            <a href="/WhatWeDo/SundaySchool"><img className="img img-responsive" width="217" height="140" src={sundaySchool} alt="Sunday School" title="Sunday School" /></a>
                            <h4><a href="/WhatWeDo/SundaySchool">Sunday School</a></h4>
                          </div>

                          <div className="col-md-3 col-sm-6">
                            <a href="/WhatWeDo/GrowthGroups"><img className="img img-responsive" width="217" height="140" src={growthGroups} alt="Growth Groups" title="Growth Groups" /></a>
                            <h4><a href="/WhatWeDo/GrowthGroups">Growth Groups</a></h4>
                          </div>

                          <div className="col-md-3 col-sm-6">
                            <a href="/WhatWeDo/LeafYouth"><img className="img img-responsive" width="217" height="140" src={leafYouth} alt="Leaf Youth" title="Leaf Youth" /></a>
                            <h4><a href="/WhatWeDo/LeafYouth">Leaf Youth</a></h4>
                          </div>

                          <div className="col-md-3 col-sm-6">
                            <a href="/WhatWeDo/LittleBuddies"><img className="img img-responsive" width="217" height="140" src={littleBuddies} alt="Little Buddies" title="Little Buddies" /></a>
                            <h4><a href="/WhatWeDo/LittleBuddies">Little Buddies</a></h4>
                          </div>


                        </div>
                      </div>



                    </div>
                  </div>
                </div>
              </div>








            </div>
          </div>
        </div >
      </section >
    );
  }
}

export default WhatWeDo;
