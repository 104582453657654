import React, { Component } from 'react';
import styled from '@emotion/styled'
import sundayMorning from '../../assets/TB new header.png'

const Container = styled.div`
text-align: center;
img {
  max-width: 1280px;
  display: inline-block;
}
`;

const slides = [{
  "src": sundayMorning,
  "alt": "",
  "caption":
  {
    "header": null
  }
}
];

class Slider extends Component {
  render() {
    return (
      <Container>
        <img className="img-responsive img-full-width" src={slides[0].src} width="1440" height="600" alt={slides[0].alt} title={slides[0].alt} />
      </Container>
    );
  }
}

export default Slider;
