/* eslint-disable */
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import OtherPageContent from './OtherPageContent';


import WhatWeBelieve from './pages/About/WhatWeBelieve';
import WhoWeAre from './pages/About/WhoWeAre';
import WhatWeDo from './pages/About/WhatWeDo';
import MoreOnWhatWeBelieve from './pages/About/MoreOnWhatWeBelieve';

import Visit from './pages/VisitUs/Visit';
import WhatToExpect from './pages/VisitUs/WhatToExpect';

//import AllSermons from './pages/Resources/AllSermons';
import Sermons from './pages/Resources/Sermons';
import SermonPage from './pages/Resources/SermonPage';
import SermonSeriesPage from './pages/Resources/SermonSeriesPage';


import TheGoodNews from './pages/TheGoodNews'
import ContactUs from './pages/ContactUs';
import Calendar from './pages/Resources/Calendar';
import ContactInfo from './pages/Connect/ContactInfo';
import ExploreChristianity from './pages/Connect/ExploreChristianity';
//import Sundays from './pages/WhatWeDo/Sundays';
import LeafKids from './pages/WhatWeDo/LeafKids';
import GrowthGroups from './pages/WhatWeDo/GrowthGroups';
import LeafYouth from './pages/WhatWeDo/LeafYouth';
import LittleBuddies from './pages/WhatWeDo/LittleBuddies';


class OtherPageWrapper extends Component {
  render() {
    return (
      <section>
        <Switch>

          {/*<Route exact path="/AllSermons" component={AllSermons} />*/}
          <Route exact path="/Sermons" component={Sermons} />
          <Route exact path="/sermon/:nid" component={SermonPage} />
          <Route exact path="/sermon/:nid/:title" component={SermonPage} />
          <Route exact path="/series/:nid" component={SermonSeriesPage} />
          <Route exact path="/series/:nid/:title" component={SermonSeriesPage} />

          <Route exact path="/WhatWeBelieve" component={WhatWeBelieve} />
          <Route exact path="/WhoWeAre" component={WhoWeAre} />
          <Route exact path="/WhatWeDo" component={WhatWeDo} />
          <Route exact path="/WhatToExpect" component={WhatToExpect} />
          <Route exact path="/MoreOnOurBeliefs" component={MoreOnWhatWeBelieve} />

          <Route exact path="/ContactInfo" component={ContactInfo} />
          <Route exact path="/ChristianityExplored" component={ExploreChristianity} />
          <Route exact path="/Visit" component={Visit} />

          {/*<Route exact path="/WhatWeDo/Sundays" component={Sundays} />*/}
          <Route exact path="/WhatWeDo/LeafKids" component={LeafKids} />
          <Route exact path="/WhatWeDo/GrowthGroups" component={GrowthGroups} />
          <Route exact path="/WhatWeDo/LeafYouth" component={LeafYouth} />
          <Route exact path="/WhatWeDo/LittleBuddies" component={LittleBuddies} />

          <Route exact path="/WhoIsJesus" component={TheGoodNews} />

          <Route exact path="/ContactUs" component={ContactUs} />
          <Route exact path="/Calendar" component={Calendar} />

          <Route path="/*" component={OtherPageContent} />
        </Switch>
      </section>
    );
  }
}

export default OtherPageWrapper;
