import React from 'react';

const Person = props => {
  const alignment = props.align === "right" ? "alignright" : "alignleft";
  return (
    <div className="row col-xs-12" style={{ marginTop: '30px' }}>
      <div className="mobile-only text-center">
        <br />
        <img className="img img-responsive" src={props.image} alt="" width="200" height="203" style={{ marginBottom: "20px" }} />
      </div>
      <img className={`desktop-only ${alignment}`} src={props.image} alt="" width="200" height="203" />
      <p dangerouslySetInnerHTML={{ __html: props.blurb }} />
    </div>
  );
};

export default Person;