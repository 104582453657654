import React, { Component } from 'react';


class CarouselItem extends Component {
    constructor(props) {
        super(props);
        this.state = { ...props }
    }
    render() {
        return (
            <div className="text-center">
                <a href={this.state.url}><img src={this.state.img} alt={this.state.name} title={this.state.name} /></a>
                {/*<h4><a href={this.state.url}>{this.state.name}</a></h4>*/}
            </div>
        );
    }
}

export default CarouselItem;