import React from 'react';

const Services = () => {
  return (
    <div className="col-md-4 col-xs-12">
      <div className="region region-content-2-2">
        <div className="block block-block">
          <h2>Services</h2>
          <div className="content">
            <h4>Sundays at 10am and 4pm</h4>
            <div id="map_canvas" className="google-map" style={{ maxHeight: "320px" }}></div>
            <div style={{ marginTop: "15px" }}><a href="https://goo.gl/maps/Ftf6fiF12EF2" target="_blank" rel="noreferrer noopener"><h4>20/2 Innocent St,<br />Kings Meadows, Tasmania</h4></a></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;