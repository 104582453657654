import React, { Component } from 'react';

class WhatToExpect extends Component {

  render() {
    return (
      <section>
        <div id="top-content-region" className="top-content padding-top-15 padding-bottom-15 block-15 bg-color-grayLight1">
          <div className="container">
            <div className="row">
              <div id="top-content-left-region" className="top-content-left col-xs-12 col-md-6 text-center-sm">
                <div id="page-title-block" className="page-title block">
                  <h1>What To Expect</h1>
                </div>
              </div>

              <div id="top-content-right-region" className="top-content-right col-xs-12 col-md-6 text-right text-center-sm">
                <div id="page-breadcrumbs-block" className="page-breadcrumbs block">
                  <div className="breadcrumbs">
                    <a href="/">Home</a>
                    <span className="delimiter">›</span>
                    <span title="" className="nolink">Visit Us</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="content-region">
          <div className="container">
            <div className="row">
              <div id="main-content-region" className="main-content col-xs-12">
                <div className="region region-content">
                  <div id="block-system-main" className="block block-system">
                    <div className="content">
                      <div className="node node-page clearfix">
                        <div className="content">
                          <div className="clearfix post-1016134 page type-page status-publish hentry">
                            <p><strong>Lots of people aren’t used to coming along to a church service, so here’s some answers to questions you might have.</strong></p>
                            <h3>What do you do?</h3>
                            <div style={{ margin: "0px 0px 20px 0px", padding: "20px 20px 1px 20px", backgroundColor: "#faf9f9" }}>
                              <p>We really just do four things: sing, pray, read the Bible and then listen to the Bible explained (that goes for about 30 minutes). You don’t have to join in the singing, and if you don’t have a Bible we have plenty that you can pick up before the service starts.</p>
                              <p>No participation is required. You won’t be asked to do anything you don’t want to do and you are more than welcome to leave whenever you want.</p>
                            </div>
                            <h3>I’m not a Christian. Am I welcome?</h3>
                            <div style={{ margin: "0px 0px 20px 0px", padding: "20px 20px 1px 20px", backgroundColor: "#faf9f9" }}>
                              <p>Absolutely! Everyone is welcome. We’d love to get to know you and we’d love for you to get to know <strong><a title="What is the Good News?" href="/WhoIsJesus">Jesus</a></strong>.</p>
                            </div>
                            <h3>What time should I arrive?</h3>
                            <div style={{ margin: "0px 0px 20px 0px", padding: "20px 20px 1px 20px", backgroundColor: "#faf9f9" }}>
                              <p>The service starts at 10am on Sunday morning and 4pm on Sunday afternoon. You can arrive on the dot if you want or a little before. We have a coffee machine running from half an hour before both services where you can purchase coffee for $2 with the proceeds going to mission work.</p>
                              <p>You can also come part way through for a little bit if you just want to sneak a look at what we do.</p>
                            </div>
                            <h3>How long does the service go for?</h3>
                            <div style={{ margin: "0px 0px 20px 0px", padding: "20px 20px 1px 20px", backgroundColor: "#faf9f9" }}>
                              <p>The service goes for about an hour to an hour and a half. You don’t have to stay the whole time. We won’t mind if you leave part way through.</p>
                              <p>We have morning tea afterwards too which you’re more than welcome to stay for.</p>
                            </div>
                            <h3>What should I wear?</h3>
                            <div style={{ margin: "0px 0px 20px 0px", padding: "20px 20px 1px 20px", backgroundColor: "#faf9f9" }}>
                              <p>Wear what ever you want. Most of us just wear jeans and a t-shirt, or something like that.</p>
                            </div>
                            <h3>Can I bring my kids?</h3>
                            <div style={{ margin: "0px 0px 20px 0px", padding: "20px 20px 1px 20px", backgroundColor: "#faf9f9" }}>
                              <p>Yep. We run a kids programs&nbsp;for the kids during the service. The kids join in with everyone for the start of the service and then leave after about 20 minutes to go to Sunday school. But if you prefer your kids can stay with you through the service. There’s also a cry room and change facilities and a crèche which runs at the 10am service.</p>
                              <p>To find out more about the kids programs check out the info on our <strong><a href="/WhatWeDo/Sundays">Sunday</a></strong> services.</p>
                            </div>

                          </div>


                        </div>
                      </div>
                    </div>
                  </div>  </div>



              </div>




            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default WhatToExpect;
