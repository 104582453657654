import React, { Component } from 'react';

import visit from '../../../assets/visit.jpg';

class Visit extends Component {

  render() {
    return (
      <section>
        <div id="top-content-region" className="top-content padding-top-15 padding-bottom-15 block-15 bg-color-grayLight1">
          <div className="container">
            <div className="row">
              <div id="top-content-left-region" className="top-content-left col-xs-12 col-md-6 text-center-sm">
                <div id="page-title-block" className="page-title block">
                  <h1>Visit Us</h1>
                </div>
              </div>

              <div id="top-content-right-region" className="top-content-right col-xs-12 col-md-6 text-right text-center-sm">
                <div id="page-breadcrumbs-block" className="page-breadcrumbs block">
                  <div className="breadcrumbs">
                    <a href="/">Home</a>
                    <span className="delimiter">›</span>
                    <span title="" className="nolink">Visit Us</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="content-region">
          <div className="container">
            <div className="row">
              <div id="main-content-region" className="main-content col-xs-12">
                <div className="region region-content">
                  <div id="block-system-main" className="block block-system">
                    <div className="content">
                      <div className="node node-page clearfix">
                        <div className="content">


                          <img style={{ float: "right", marginLeft: "20px" }} className="desktop-only" title="Sunday Morning" src={visit} width="300" height="225" alt="" />
                          <div className="mobile-only text-center">
                            <img className="img img-responsive" title="Sunday Morning" src={visit} width="300" height="225" alt="" />
                          </div>

                          <br />
                          <p style={{ marginTop: "20px" }}>The best way to find out about who we are and what we do is to come along on a Sunday. We have two services.</p>
                          <p>We also meet for a special 10am service on Good Friday and Christmas Day.</p>
                          <h3>Sunday 10am</h3>
                          <p>For all ages. Espresso coffee from 9.15–9.50 with kick-off at 10am. Crèche for age 0-3s and a kids’ programs for children age 4 to grade 6. Tea and coffee afterwards.</p>

                          <h3>Sunday 4pm</h3>
                          <p>For all ages. Espresso coffee from 3.30-3:50 with kick-off at 4pm. Kids program for children age 4 to grade 2.</p>

                          <p>You can find out where we are <a href="/ContactInfo">here</a>.</p>

                          <p style={{ margin: "40px 0 40px 0" }}><strong>Lots of people aren’t used to coming along to a church service, so here’s some answers to questions you might have.</strong></p>

                          {/*What to Expect*/}
                          <h3>What do you do?</h3>
                          <div style={{ margin: "0px 0px 20px 0px", padding: "20px", backgroundColor: "#faf9f9" }}>
                            <p>We really just do four things: sing, pray, read the Bible and then listen to the Bible explained (that goes for about 30 minutes). You don’t have to join in the singing, and if you don’t have a Bible we have plenty that you can pick up before the service starts.</p>
                            <p>No participation is required. You won’t be asked to do anything you don’t want to do and you are more than welcome to leave whenever you want.</p>
                          </div>
                          <h3>I’m not a Christian. Am I welcome?</h3>
                          <div style={{ margin: "0px 0px 20px 0px", padding: "20px", backgroundColor: "#faf9f9" }}>
                            <p>Absolutely! Everyone is welcome. We’d love to get to know you and we’d love for you to get to know <strong><a title="What is the Good News?" href="/WhoIsJesus">Jesus</a></strong>.</p>
                          </div>
                          <h3>What time should I arrive?</h3>
                          <div style={{ margin: "0px 0px 20px 0px", padding: "20px", backgroundColor: "#faf9f9" }}>
                            <p>The service starts at 10am on Sunday morning and 4pm on Sunday afternoon. You can arrive on the dot if you want or a little before. We have a coffee machine running from half an hour before both services where you can purchase coffee for $2 with the proceeds going to mission work.</p>
                            <p>You can also come part way through for a little bit if you just want to sneak a look at what we do.</p>
                          </div>
                          <h3>How long does the service go for?</h3>
                          <div style={{ margin: "0px 0px 20px 0px", padding: "20px", backgroundColor: "#faf9f9" }}>
                            <p>The service goes for about an hour to an hour and a half. You don’t have to stay the whole time and we won’t mind if you leave part way through.</p>
                            <p>At both services we serve coffee in the foyer, 30 mins before the service. Afterwards at the 10am service, we have morning tea too, which you’re more than welcome to stay for.</p>
                          </div>
                          <h3>What should I wear?</h3>
                          <div style={{ margin: "0px 0px 20px 0px", padding: "20px", backgroundColor: "#faf9f9" }}>
                            <p>Wear what ever you want. Most of us just wear jeans and a t-shirt, or something like that.</p>
                          </div>
                          <h3>Can I bring my kids?</h3>
                          <div style={{ margin: "0px 0px 20px 0px", padding: "20px", backgroundColor: "#faf9f9" }}>
                            <p>Yep. We run a kids programs&nbsp;for the kids during the service. The kids join in with everyone for the start of the service and then leave after about 20 minutes to go to Sunday school. But if you prefer your kids can stay with you through the service. There’s also a cry room and change facilities and a crèche which runs at the 10am service.</p>
                          </div>

                        </div>

                      </div>





                    </div>
                  </div>
                </div>



              </div>




            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Visit;
