/* eslint-disable */
import React, { Component } from 'react';
import leafImg from '../../../assets/LfY_web_small.png';

class LeafYouth extends Component {
    render() {
        return (
            <section>
                <div id="top-content-region" className="top-content padding-top-15 padding-bottom-15 block-15 bg-color-grayLight1">
                    <div className="container">
                        <div className="row">
                            <div id="top-content-left-region" className="top-content-left col-xs-12 col-md-6 text-center-sm">
                                <div id="page-title-block" className="page-title block">
                                    <h1>Leaf Youth</h1>
                                </div>
                            </div>

                            <div id="top-content-right-region" className="top-content-right col-xs-12 col-md-6 text-right text-center-sm">
                                <div id="page-breadcrumbs-block" className="page-breadcrumbs block">
                                    <div className="breadcrumbs">
                                        <a href="/">Home</a>
                                        <span className="delimiter">›</span>
                                        <a href="/WhatWeDo">What We Do</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="content-region">
                    <div className="container">
                        <div className="row">
                            <div id="main-content-region" className="main-content col-xs-12">
                                <div className="region region-content">
                                    <div id="block-system-main" className="block block-system">
                                        <div className="content">
                                            <div className="node node-page clearfix">
                                                <div className="content">

                                                    <div className="text-center mobile-only">
                                                        <img className="img img-responsive" alt="" src={leafImg} width="300" height="193" />
                                                    </div>
                                                    <img style={{ float: "right", marginLeft: "20px", marginTop: "-35px" }} className="desktop-only" alt="" src={leafImg} width="300" height="193" />
                                                    <p>
                                                        Leaf Youth is a group of Grade 7-12s who meet together weekly to encourage one another and to grow in relationship with God. We have five youth leaders who are committed to helping teenagers learn about Jesus and commit to Him.<br />
                                                        Typically, we hang out, play some games, study God's word in small groups, pray together, and finish with supper.<br />
                                                        We meet from 6:45 to 8:45pm every Wednesday during school terms. Visitors are always welcome!
                                                    </p>



                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </div>




                        </div>
                    </div>
                </div>
            </section >
        );
    }
}

export default LeafYouth;
