import React from 'react';
import styled from "styled-components";
import CarouselItem from './CarouselItem'

import christiantyExplored from '../../assets/Christianity Explored Card.png'
import sundaySchool from '../../assets/LfK_web_small.png'
import growthGroups from '../../assets/GG_web_small.png'
import leafYouth from '../../assets/LfY_web_small.png'
import littleBuddies from '../../assets/LB_web_small.png'

const MinistriesContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  grid-column: auto / span 2;
  align-content: center;
  grid-gap: 30px;
  div{
    grid-column: auto / span 2;
  }
  div:nth-child(4){
    grid-column-start: 2;
  }
  @media (max-width: 700px) {
    grid-template-columns: auto auto auto auto;
    div:nth-child(4){
      grid-column-start: auto;
    }

  }
  @media (max-width: 462px) {
    grid-template-columns: auto auto;
  }
  margin-bottom: 50px;
`

const Ministries = () => {
  return (
    <div className="container" style={{ marginTop: "20px" }}>
      <h2>What We Do</h2>
      <MinistriesContainer>
        <CarouselItem name="Christianity Explored" img={christiantyExplored} url="/ChristianityExplored" />
        <CarouselItem name="Little Buddies" img={littleBuddies} url="/WhatWeDo/LittleBuddies" />
        <CarouselItem name="Leaf Youth" img={leafYouth} url="/WhatWeDo/LeafYouth" />
        <CarouselItem name="Growth Groups" img={growthGroups} url="/WhatWeDo/GrowthGroups" />
        <CarouselItem name="Leaf Kids" img={sundaySchool} url="/WhatWeDo/LeafKids" />
      </MinistriesContainer>
    </div>
  );
};

export default Ministries;