/* eslint-disable */
import React, { Component } from 'react';
import image from '../../../assets/LfK_web_small.png';

class SundaySchool extends Component {
    render() {
        return (
            <section>
                <div id="top-content-region" className="top-content padding-top-15 padding-bottom-15 block-15 bg-color-grayLight1">
                    <div className="container">
                        <div className="row">
                            <div id="top-content-left-region" className="top-content-left col-xs-12 col-md-6 text-center-sm">
                                <div id="page-title-block" className="page-title block">
                                    <h1>Leaf Kids</h1>
                                </div>
                            </div>

                            <div id="top-content-right-region" className="top-content-right col-xs-12 col-md-6 text-right text-center-sm">
                                <div id="page-breadcrumbs-block" className="page-breadcrumbs block">
                                    <div className="breadcrumbs">
                                        <a href="/">Home</a>
                                        <span className="delimiter">›</span>
                                        <a href="/WhatWeDo">What We Do</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="content-region">
                    <div className="container">
                        <div className="row">
                            <div id="main-content-region" className="main-content col-xs-12">
                                <div className="region region-content">
                                    <div id="block-system-main" className="block block-system">
                                        <div className="content">
                                            <div className="node node-page clearfix">
                                                <div className="content">


                                                    <div className="text-center mobile-only" style={{ marginBottom: '20px' }}>
                                                        <img className="img img-responsive" title="A Sunday School Puppet Show" alt="A Sunday School Puppet Show" src={image} width="300" height="193" />
                                                    </div>
                                                    <img style={{ float: "right", marginLeft: "20px" }} className="desktop-only" title="A Sunday School Puppet Show" alt="A Sunday School Puppet Show" src={image} width="300" height="193" />
                                                    <p>We run regular sessions for children during our services on the Sundays following school weeks. After spending the first ~15 minutes of the service with the rest of the church family, children are invited to participate in tailored sessions instead of listening to the sermon.</p>
                                                    <p>At our 10am service, there are two groups that meet: one for children from age 4 to grade 2, and one for children in grades 3 to 6. </p>
                                                    <p>At our 4pm service, there is one group for children from age 4 to grade 2. </p>
                                                    <p>In these sessions, the leaders teach about who Jesus is, the wonderful things He has done, is doing and will do, and what it looks like to follow Him. Parents are welcome to chat with the leaders after services.</p>
                                                    <p>All our leaders have a current Working With Vulnerable People registration, have undertaken Safe Ministry Training, and are required to comply with our Safe Ministry Policy. </p>
                                                </div>
                                                <div className="text-center" style={{ marginTop: '20px' }}>
                                                    <a href='https://safeministrycheck.com.au' target='_blank'><embed style={{ width: "100%", height: "auto", maxWidth: '500px' }} src="https://mychurch.safeministrycheck.com.au/verify/church_badge.php?x=ZjZPQ2pXYWxqcmVCSk4zcFJMRytrUT09&c=092136" /></a>
                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </div>




                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default SundaySchool;
