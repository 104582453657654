import React, { Component } from "react";
import photo3 from "../../../assets/photo3.jpg";
import { GetInTouchUrl } from "../../../lib/config";

class WhatWeBelieve extends Component {
	render() {
		return (
			<section>
				<div
					id="top-content-region"
					className="top-content padding-top-15 padding-bottom-15 block-15 bg-color-grayLight1"
				>
					<div className="container">
						<div className="row">
							<div id="top-content-left-region" className="top-content-left col-xs-12 col-md-6 text-center-sm">
								<div id="page-title-block" className="page-title block">
									<h1>What We Believe</h1>
								</div>
							</div>

							<div
								id="top-content-right-region"
								className="top-content-right col-xs-12 col-md-6 text-right text-center-sm"
							>
								<div id="page-breadcrumbs-block" className="page-breadcrumbs block">
									<div className="breadcrumbs">
										<a href="/">Home</a>
										<span className="delimiter">›</span>
										<span title="" className="nolink">
											About
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div id="content-region">
					<div className="container">
						<div className="row">
							<div id="main-content-region" className="main-content col-xs-12">
								<div className="region region-content">
									<div id="block-system-main" className="block block-system">
										<div className="content">
											<div className="node node-page clearfix">
												<div className="content">
													<div class="clearfix post-370 page type-page status-publish hentry">
														<p className="text-justify">
															<img
																class="img-responsive img alignright"
																title="photo (3)"
																src={photo3}
																alt=""
																width="300"
																height="300"
															/>
															At the Branch Christian Church, we believe that the Bible is the inspired Word of God and
															that it is the final authority on everything to do with faith and life. We believe that
															Jesus Christ is the Son of God. We also believe that roughly 2000 years ago God sent him
															to earth to live a perfect life and to die on a cross, cursed by God. Three days later he
															rose from the dead, proving that he was who he said he was and proving that&nbsp;he
															defeated death so that everyone who trusts in him will be rescued from the punishment they
															deserve due to their rebellion against God and one day be raised to everlasting life.
															&nbsp;We try to tell everyone this good news so that through faith in Jesus we can all
															have hope for the future.
														</p>
														<p className="text-justify">
															<strong>If you would like to know more</strong> about Christianity and what it is all
															about:
														</p>
														<ul>
															<li className="text-justify">
																<a href="/Visit">
																	<strong>Come along and ask one of us</strong>
																</a>
																.
															</li>
															<li className="text-justify">
																<strong>
																	<a href="/ChristianityExplored">Sign Up for Christianity Explored</a>
																</strong>
																, an&nbsp;<strong>8-week course designed to explain the basics of Christianity</strong>,
																like who God is, who Jesus is, why we need Jesus and how he helps us.
															</li>
															<li className="text-justify">
																<a href={GetInTouchUrl} data-open-in-church-center-modal="true">
																	<strong>Get in touch</strong>
																</a>
																.
															</li>
														</ul>

														<h5>
															More detailed information about our beliefs can be found on{" "}
															<a href="/MoreOnOurBeliefs">this page</a>.
														</h5>
													</div>
												</div>
											</div>
										</div>
									</div>{" "}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default WhatWeBelieve;
