/* eslint-disable */
import React, { Component } from "react";
import painting from "../../../assets/20-11-14-1-resized-214x300.jpg";
import { GetInTouchUrl } from "../../../lib/config";

class LittleBuddies extends Component {
	render() {
		return (
			<section>
				<div
					id="top-content-region"
					className="top-content padding-top-15 padding-bottom-15 block-15 bg-color-grayLight1"
				>
					<div className="container">
						<div className="row">
							<div id="top-content-left-region" className="top-content-left col-xs-12 col-md-6 text-center-sm">
								<div id="page-title-block" className="page-title block">
									<h1>Little Buddies</h1>
								</div>
							</div>

							<div
								id="top-content-right-region"
								className="top-content-right col-xs-12 col-md-6 text-right text-center-sm"
							>
								<div id="page-breadcrumbs-block" className="page-breadcrumbs block">
									<div className="breadcrumbs">
										<a href="/">Home</a>
										<span className="delimiter">›</span>
										<a href="/WhatWeDo">What We Do</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div id="content-region">
					<div className="container">
						<div className="row">
							<div id="main-content-region" className="main-content col-xs-12">
								<div className="region region-content">
									<div id="block-system-main" className="block block-system">
										<div className="content">
											<div className="node node-page clearfix">
												<div className="content">
													<div className="mobile-only text-center">
														<img
															className="img img-responsive"
															src={painting}
															alt=""
															width="212"
															height="300"
															style={{ marginBottom: "20px" }}
														/>
													</div>

													<div className="desktop-only">
														<img
															style={{ float: "right" }}
															className="margin-left-15"
															src={painting}
															alt=""
															width="212"
															height="300"
														/>
													</div>
													<p>
														<strong>What:</strong> Little Buddies is a fun, caring and creative place for young children
														(0-5 yrs) and their parents/carers to play and socialise together.
													</p>
													<p className="padding-top-15">
														<strong>Where:</strong> The Branch Community Centre, Kings Meadows
													</p>
													<p className="padding-top-15">
														<strong>When:</strong> Wednesdays (during school term) from 9.30-11:30am.
													</p>
													<p className="padding-top-15 no-margin-bottom">
														<strong>Cost:</strong> $5.00 per family yearly registration (once off payment)
													</p>
													<p className="padding-left-40">$2.00 per family per session</p>
													<p className="padding-top-15">
														<strong>What do we do at Playgroup:</strong>&nbsp;Play dough and puzzles, painting, drawing
														and craft, play in the kids kitchen and dress up corner, play with trains, trucks and cars,
														slide down slides, ride bikes, crawl through tunnels, eat morning tea together, sing songs,
														say rhymes, and listen to stories.
													</p>
													<p className="padding-top-15">
														<strong>A typical Little Buddies session:</strong>
													</p>
													<p className="padding-left-30">
														9:30 – free play
														<br />
														10:00 – mat time (welcome, book, song)
														<br />
														10:15 – activity/craft
														<br />
														10:45 – morning tea
														<br />
														11:00 – free play
														<br />
														11:30 – home
													</p>
													<p className="padding-top-15">
														<strong>Snacks:</strong> Please bring water bottles, hats, and morning tea for you/children.
														Tea/Coffee provided.
													</p>
													<p className="padding-top-15">
														If you want to know more{" "}
														<a href={GetInTouchUrl} data-open-in-church-center-modal="true">
															get in touch
														</a>
														.
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default LittleBuddies;
